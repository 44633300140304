import axios from 'axios';
import log from './logger';

let tokenapi = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}/api/v1/token/get`,
});

let secureapi = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}/api/v1`,
});
secureapi.defaults.headers.post['Content-Type'] = 'application/json';
secureapi.interceptors.response.use(function (response) {
    log('secureapi response interceptor', response);
    setToken(response.headers['apptoken']);
    return response;
}, function (error) {
    log('secureapi error interceptor', error);
    if ( error.response.status < 500 ) {
        return Promise.resolve({ status: error.response.status, error: error.response.data.error } );
    }
    return Promise.reject(error);
});

function setToken(token) {
    if (token && token != ""){
        secureapi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete secureapi.defaults.headers.common['Authorization'];
    }
}
function currentToken() {
    return secureapi.defaults.headers.common['Authorization'].split(" ")[1];
}

async function getToken({email,password}) {
    try {
        let response = await tokenapi.post("",{
            email,
            password:password
        });
        let r = { status: response.status, token: response.data};
        setToken(response.data.token);
        log('getToken', r);
        return r;
    } catch (e){
        let r = {};
        if (e.response) {
            r = {status:e.response.status};
        } else {
            r = {status:500};
        }
        log('getToken error', r, e);
        return r;
    }
}

async function getProfile(){
    try {
        let r = await secureapi.get(`/user/profile`);
        log('post /user/profile', r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function register(data){
    try {
        let r = await secureapi.post(`/user/register`,JSON.stringify(data));
        log('post /user/register', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function newPassword(email){
    try {
        let r = await secureapi.post(`/user/newpassword`,JSON.stringify({email}));
        log('post /user/newpassword', email, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function changepassword(data){
    try {
        let r = await secureapi.post(`/user/changepassword`,JSON.stringify(data));
        log('post /user/changepassword', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function changeprofile(data){
    try {
        let r = await secureapi.post(`/user/changeprofile`,JSON.stringify(data));
        log('post /user/changeprofile', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function getAvailableValorationDates(data){
    try {
        let r = await secureapi.post(`/valoration/available/dates`,JSON.stringify(data));
        log('post /valoration/available/dates', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function getForwards(filters){
    try {
        let data = await secureapi.get(`/forward`,{params:filters});
        log('get forward list',filters);
        return data;
    } catch (e) {
        log(e);
        return { status: 500 };
    }
}
async function getForward(id){
    try {
        let data = await secureapi.get(`/forward/${id}`);
        log('get forward', id, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function createForward(data){
    try {
        let r = await secureapi.post(`/forward`,JSON.stringify(data));
        log('post forward', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function updateForward(data){
    try {
        let r = await secureapi.put(`/forward/${data.id}`,data);
        log('put forward', data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function deleteForward(id){
    try {
        let data = await secureapi.delete(`/forward/${id}`);
        log('delete entity', id, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function getEntities(entity,year){
    try {
        let data = await secureapi.get(`/${entity}/year/${year}`);
        log('get entity year', entity, year, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function getEntity(entity,id){
    try {
        let data = await secureapi.get(`/${entity}/${id}`);
        log('get entity', entity, id, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function createEntity(entity,data){
    try {
        let r = await secureapi.post(`/${entity}`,JSON.stringify(data));
        log('post entity', entity, data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function updateEntity(entity,data){
    try {
        let r = await secureapi.put(`/${entity}/${data.id}`,data);
        log('put entity', entity, data, r);
        return r;
    } catch (e) {
        return { status: 500 };
    }
}
async function deleteEntity(entity,id){
    try {
        let data = await secureapi.delete(`/${entity}/${id}`);
        log('delete entity', entity, id, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function doValoration(key, ids) {
    try {
        let data = await secureapi.post(`/forward/valorate`,{key, ids});
        log('forward valorate', key, ids, data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}
async function hasPendingPayment() {
    try {
        let data = await secureapi.get(`/payment/pending`);
        log('payment check', data);
        return data;
    } catch (e) {
        return { status: 500 };
    }
}

async function getUsers() {
    try {
        let data = await secureapi.get(`/suscription`);
        return data;
    } catch (e) {
        return {status: 500};
    }
}

async function editSuscription(users, planId) {
    try {
        let data = await secureapi.put(`/suscription/edit`, {users, planId});
        return data;
    } catch (e) {
        return {status: 500};
    }
}

export default {
    hasPendingPayment,
    register,
    newPassword,
    getProfile,
    changepassword,
    changeprofile,
    getAvailableValorationDates,
    getForwards,
    getForward,
    createForward,
    updateForward,
    deleteForward,
    getEntities,
    getEntity,
    createEntity,
    updateEntity,
    deleteEntity,
    getToken,
    setToken,
    doValoration,
    getUsers,
    editSuscription,
    downloadFile: `${process.env.REACT_APP_API_SERVER}/api/v1/valoration/pdf`,
    downloadXLSXFile: `${process.env.REACT_APP_API_SERVER}/api/v1/valoration/xlsx`,
    currentToken,
    startPaymentURL: `${process.env.REACT_APP_API_SERVER}/v1/payment/init`
};