
const pibRates = [7,25,48,76,136,226,316,450,540,720];
const tccRates = ['',30,90,180,360,720];

export default {
    trm: {
        tableSpec: {size:6,offset:3},
        admin: true,
        menuText: 'TRM',
        entityId: 'trm',
        extraFields: [{
            name: 'rate',
            userText: 'Tasa'
        }],
        precision: 2
    },
    pip: {
        tableSpec: {size:12,offset:0},
        editSpec: {size:6,offset:6},
        admin: true,
        menuText: 'PIP',
        entityId: 'pip',
        extraFields: pibRates.map(id=>({
            name: `rate${id}`,
            userText: `<= ${id} días`
        })),
        precision: 2
    },
    tcc: {
        tableSpec: {size:8,offset:2},
        editSpec: {size:6,offset:6},
        admin: true,
        menuText: 'TCC',
        entityId: 'tcc',
        extraFields: tccRates.map(id=>({
            name: `rate${id}`,
            userText: `${id==''?'Tasa':'<= ' + id + 'días'}`
        })),
        precision: 3
    },
};