import React, { useEffect } from 'react'
import { useApi } from '../Apiprovider';
import Loading from './Loading';
import history from '../utils/history';

const SecureComponent = ({ adminRequired, children, loginRoute = '/login', homeRoute = '/' }) => {

    const auth = useApi();
    const isAuthenticated = auth.isAuthenticated();
    const isAdmin = auth.isAdmin();

    useEffect(() => {
        if (!isAuthenticated) {
            history.push(loginRoute);
        } else if (!isAdmin && adminRequired == true) {
            history.push(homeRoute);
        }
    }, [isAuthenticated, isAdmin, adminRequired]);

    if (!isAuthenticated || !isAdmin && adminRequired == true) {
        return <Loading></Loading>;
    }

    return <>{children}</>;
};

export default SecureComponent;