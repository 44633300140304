
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';

export default styled(DatePicker)`
& {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    text-align: end;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
`;