import React, { usedata, useEffect, useState } from "react";
import { Container, Row, Col, Button,Form, FormGroup, Label, Alert,
  Modal, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useHistory } from "react-router-dom";
import { InputNumberFormat } from '../components/NumberFormat';
import DatePicker from '../components/DatePicker';
import { format as dateFormat, parse as dateParse } from 'date-format-parse';
import Loading from "../components/Loading";
import SecureComponent from "../components/SecureComponent";
import  api from '../api';
const NONE = 10;
const SAVE = 11;
const DELETE = 12;
const DODELETE = 13;


const GenericEditComponent = ({mode = "edit", spec}) => {
  const { id } = useParams();
  const history = useHistory();

  const isEdit = mode == "edit";
  const isNew = mode == "new";
  const [state,setState] = useState(NONE);
  const [isLoading,setLoading] = useState(isEdit);
  const [error,setError] = useState(false);

  const [data,setData] = useState({
    id: isNew ? new Date() : dateParse(id,'YYYYMMDD'), // Date
  });

  const idStr = dateFormat(data.id,'YYYYMMDD');
  const guiIdStr = dateFormat(data.id,'YYYY/MM/DD');

  const validForm = !spec.extraFields.some(f=>data[f.name]==""||data[f.name]==null) && data.id != null;

  const dismissDialog = () => {
    setLoading(false);
    setState(NONE);
    setError(false);
  };
  const doDelete = () => {
    setLoading(true);
    setState(DODELETE);
    setError(false);
  };
  const doSave = () => {
    setLoading(true);
    setState(SAVE);
    setError(false);
  }
  const askDelete = () => {
    setState(DELETE);
    setError(false);
  };
  const setDate = (e) => {
    setData({...data,id: e});
    setError(false);
  };
  const setRate = (field,v) => {
    setData({...data,[field]: v});
    setError(false);
  };

  const callApi = async () => {
    try {
      if ( isEdit && state == DODELETE ) {
        let r = await api.deleteEntity(spec.entityId,idStr);
        if ( r.status == 200 ) {
          history.goBack();
          return;
        } else {
          setLoading(false);
          setError("No se puede borrar el registro.");
          setState(NONE);
        }
      }
      if ( isEdit && isLoading && state == NONE ) {
        let r = await api.getEntity(spec.entityId,idStr);
        if (r.status == 200) {
          setData({...r.data,id:dateParse(r.data.id+"",'YYYYMMDD')}); // se debe convertir a string para hacer el parsing
        } else {
          setError("No se puede obtener el registro");
        }
        setState(NONE);
        setLoading(false);
        return;
      }
      if ( state == SAVE ) {
        let f = isEdit ? api.updateEntity : api.createEntity;
        let postdata = {...data,id:idStr};
        let r = await f(spec.entityId,postdata);
        if (r.status==409){
          setLoading(false);
          setError('Ya existe la tasa');
          setState(NONE);
          return;
        }
        if (r.status==400){
          setLoading(false);
          setError(r.error);
          setState(NONE);
          return;
        }
        history.goBack();
      }
    } catch (e){
      setLoading(false);
      setError(new String(e));
      setState(NONE);
    }
  };

  useEffect(()=>{callApi()});

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col xs="12">
          <h4>
            { isNew?"Crear":"Modificar"} {spec.menuText} {isNew?"":"- " + guiIdStr}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={{size:6,offset:3}}>
          <Form>
            {
              isEdit? <></>:
              <FormGroup row>
                <Label style={{textAlign:'right'}} sm={3}>Fecha</Label>
                <Col sm={6}>
                  <DatePicker dateFormat="yyyy/MM/dd" placeholderText='YYYY/MM/DD' selected={data.id} onChange={setDate} strictParsing></DatePicker>
                </Col>
              </FormGroup>
            }
            {
              spec.extraFields.map((field,idx)=>
              <FormGroup row key={field.name}>
                <Label sm={3} style={{textAlign:'right'}}>{field.userText}</Label>
                <Col sm={6}>
                  <InputNumberFormat value={data[field.name]} decimalScale={spec.precision} autoFocus={idx==0} onValueChange={change=> {
                    setRate(field.name,change.value);
                  }}></InputNumberFormat>
                </Col>
              </FormGroup>
              )
            }
            
            {
              error ? <FormGroup row>
              <Label sm={2}></Label>
              <Col sm={6}>
                <Alert color="warning">{error}</Alert>
              </Col>
            </FormGroup>:<></>
            }
            
            <FormGroup row>
              <Col sm={{offset:2,size:10}}>
                <Button color="primary" disabled={!validForm} onClick={doSave}><FontAwesomeIcon icon="save"/> Guardar</Button>
                &nbsp;
                {
                  isEdit ? 
                  <Button color="danger" onClick={askDelete}>
                    <FontAwesomeIcon icon="trash" /> Eliminar</Button>
                  : <></>
                }
              </Col>
              <Col sm={3}>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
        <Modal isOpen={state==DELETE}>
        <ModalBody>
          ¿Estás seguro?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={doDelete}>Si</Button>{' '}
          <Button color="primary" onClick={dismissDialog}>No</Button>{' '}
        </ModalFooter>
      </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ({mode, spec}) => (
  <SecureComponent adminRequired={true}>
    <GenericEditComponent mode={mode} spec={spec}></GenericEditComponent>
  </SecureComponent>
);
