import React, { useEffect, useState } from "react";
import {
  Container, Row, Col, Button, Form, FormGroup, Label, Alert, Input,
  Modal, ModalBody, ModalFooter
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayNumberFormat } from "../components/NumberFormat";
import { NavLink, useHistory } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { format as dateFormat, parse as dateParse } from 'date-format-parse';
import datelocale from '../utils/datelocale'
import subscriptionMap from '../subscriptions';
import Loading from "../components/Loading";
import SecureComponent from '../components/SecureComponent';
import api from '../api';
import log from '../logger';

const NONE = 0;
const CHANGEPASSWORD = 10;
const CHANGEPROFILE = 11;

export const Profile = () => {
  const history = useHistory();

  const [currentState, setCurrentState] = useState(NONE);
  const [isLoading, setLoading] = useState(true);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [profile, setProfile] = useState({ user: {}, subscription: {} });

  const pending = profile.subscription.transaction_state == 'P';
  console.log("pending", pending);
  const expireDate = profile.subscription.expire_at == null ? null : dateParse(profile.subscription.expire_at, 'YYYYMMDD');
  const expireDateString = expireDate == null ? '' :
    `${dateFormat(expireDate, 'dddd D', { locale: datelocale })} de ${dateFormat(expireDate, 'MMMM', { locale: datelocale })} de ${dateFormat(expireDate, 'YYYY', { locale: datelocale })}`
    ;
  const samePassword = currentPassword.length > 0 && newPassword.length > 0 && newPassword == currentPassword;
  const newPasswordEqual = newPassword == newPassword2;
  const enableChangePassword = currentPassword.length > 0 && newPassword.length > 0 && newPasswordEqual && !samePassword;
  const passwordError = !enableChangePassword ?
    (samePassword ? "La nueva contraseña debe ser diferente a la actual" :
      (!newPasswordEqual ? "La nueva contraseña no son iguales" : ""))
    : "";
  const currentSubscription = subscriptionMap[(profile.subscription || {}).plan_id];

  // profile
  const enableChangeProfile = profile.user.name != "" && profile.user.company != "" && profile.user.company_id != "";

  function doPasswordChange() {
    setCurrentState(CHANGEPASSWORD);
    setLoading(true);
    setAlertMsg('');
  }

  function doProfileChange() {
    setCurrentState(CHANGEPROFILE);
    setLoading(true);
    setAlertMsg('');
  }

  function changeProfile(field, event) {
    let user = {
      ...profile.user,
      [field]: event.target.value
    }
    setProfile({
      ...profile,
      user: user
    })
  }

  const callApi = async () => {
    try {
      if (isLoading && currentState == NONE) {
        let r = await api.getProfile();
        if (r.status == 200) {
          setProfile({
            user: r.data.user || {},
            subscription: r.data.subscription || {}
          });
        } else {
          setAlertMsg('No se puede obtener la información de cuenta, intente más tarde');
        }
      }
      else if (isLoading && currentState == CHANGEPASSWORD) {
        let r = await api.changepassword({ currentpassword: currentPassword, newpassword: newPassword });
        if (r.status == 200) {
          setAlertMsg('Se ha cambiado la contraseña.');
          setCurrentPassword('');
          setNewPassword('');
          setNewPassword2('');
        } else {
          setAlertMsg('No se puede cambiar la contraseña, intente más tarde.');
        }
      }
      else if (isLoading && currentState == CHANGEPROFILE) {
        let r = await api.changeprofile(profile.user);
        if (r.status == 200) {
          setAlertMsg('Se ha cambiado la información básica');
        } else {
          setAlertMsg('No se puede cambiar la información básica, intente más tarde.');
        }
      }
    } catch (error) {
      setAlertMsg(error);
    } finally {
      setLoading(false);
      setCurrentState(NONE);
    }
  };

  useEffect(() => { callApi() }, [currentState]);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col>
          <h5>Perfil</h5>
          <Form autoComplete="off">
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Nombre</Label>
              <Col sm={6}><Input type="input" value={profile.user.name} onChange={e => changeProfile('name', e)}></Input></Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Email</Label>
              <Col sm={6}><Input type="input" readOnly value={profile.user.email}></Input></Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Compañia</Label>
              <Col sm={6}><Input type="input" value={profile.user.company} onChange={e => changeProfile('company', e)}></Input></Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>NIT</Label>
              <Col sm={6}><Input type="input" value={profile.user.company_id} onChange={e => changeProfile('company_id', e)}></Input></Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ offset: 3, size: 6 }}>
                <Button color="primary" disabled={!enableChangeProfile} onClick={doProfileChange}><FontAwesomeIcon icon="save" /> Guardar Cambios</Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Suscripción</h5>
          {
            currentSubscription == null ?
              <Col sm={{ offset: 3, size: 6 }}>
                <Alert color="info">No tienes subscripción activa <Button onClick={() => history.push('/payment')} color={"primary"}>
                  Suscribrise <FontAwesomeIcon icon="sign-in-alt" size={"1x"} />
                </Button></Alert>
              </Col>
              :
              pending ?
                <Alert color="info">Tienes un pago pendiente, continua con el pago o esperar hasta que termine la transacción</Alert>
                :
                <Form autoComplete="off">
                  <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={3}>Tipo</Label>
                    <Col sm={6}><Input type="input" readOnly value={currentSubscription.descripcion || ""}></Input></Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={3}>Fecha Vencimiento</Label>
                    <Col sm={6}><Input type="input" readOnly value={expireDateString}></Input></Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={3}>Valoraciones adquiridas</Label>
                    <Col sm={6}><Input type="input" style={{ textAlign: 'right' }} readOnly
                      value={profile.subscription.max_valorations <= 0 ? 'Ilimitadas' : profile.subscription.max_valorations}></Input></Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={3}>Valoraciones realizadas</Label>
                    <Col sm={6}><Input type="input" style={{ textAlign: 'right' }} readOnly
                      value={profile.subscription.current_valorations || 0}></Input></Col>
                  </FormGroup>
                  {
                    (profile.subscription.max_valorations <= 0) ? <></> :
                      <FormGroup row>
                        <Label style={{ textAlign: 'right' }} sm={3}>Valoraciones disponibles</Label>
                        <Col sm={6}><Input type="input" style={{ textAlign: 'right', textTransform: 'bold' }} readOnly
                          value={(profile.subscription.max_valorations - profile.subscription.current_valorations || 0)}></Input></Col>
                      </FormGroup>
                  }


                </Form>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Cambio de contraseña</h5>
          <Form autoComplete="off">
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Contraseña Actual</Label>
              <Col sm={6}>
                <Input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Nueva contraseña</Label>
              <Col sm={6}>
                <Input type="password" autoComplete="off" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></Input>
                <br ></br>
                <PasswordStrengthBar password={newPassword} scoreWords={['Débil', 'Débil', 'Normal', 'Bueno', 'Fuerte']} shortScoreWord={"Muy Corto"} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Confirmar contraseña</Label>
              <Col sm={6}>
                <Input type="password" autoComplete="disabled" value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)}></Input>
              </Col>
            </FormGroup>
            {
              passwordError == '' ? <></> :
                <FormGroup row>
                  <Col sm={{ offset: 3, size: 6 }}>
                    <Alert color="warning">{passwordError}</Alert>
                  </Col>
                </FormGroup>
            }
            <FormGroup row>
              <Col sm={{ offset: 3, size: 6 }}>
                <Button color="primary" disabled={!enableChangePassword} onClick={doPasswordChange}><FontAwesomeIcon icon="save" /> Cambiar Contraseña</Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      {
        alertMsg == "" ? <></> :
          <Row>
            <Col>
              <Modal isOpen={true}>
                <ModalBody>
                  {alertMsg}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setAlertMsg('')}>Aceptar</Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
      }
    </Container>
  );
};

export default () => (
  <SecureComponent adminRequired={false}>
    <Profile />
  </SecureComponent>
);
