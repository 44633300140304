import React, {useEffect, useMemo, useState} from 'react';
import { Container, Row, Col, Alert, Button,ButtonGroup, Input, Modal,ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format as dateFormat, parse as dateParse } from 'date-format-parse';
import { NavLink, useHistory } from "react-router-dom";
import truncate from "truncate";
import datelocale from '../utils/datelocale';
import { DisplayNumberFormat } from "../components/NumberFormat"; 
import Loading from "../components/Loading";
import SecureComponent from '../components/SecureComponent';
import api from '../api';

const ALL_FILTER = 'all';
const VALID_FILTER= 'valid_only';
const EXPIRED_FILTER= 'expired_only';
const ALL_TYPE='all_types';
const BUY_TYPE='buy_only';
const SELL_TYPE='sell_only';
const ARCHIVED_TYPE='archived_only';

const middleHeader = {textAlign:'center', verticalAlign:'middle'};
const centered = {textAlign:'center'};
const alignRight = {textAlign:'right'};
const alignLeft = {textAlign:'left'};

function mapType(typ) {
    if ( typ === "BUY" ){
        return "Compra";
    }
    return "Venta";
}
function humanDate(datestr){
    return dateFormat(dateParse(datestr,"YYYY-MM-DD"),"YYYY-MM-DD",datelocale)
}
function humanInternalDate(datestr){
  return dateFormat(dateParse(new String(datestr),"YYYYMMDD"),"YYYY-MM-DD",datelocale)
}
function internalDate(datestr){
  return dateFormat(dateParse(datestr,"YYYY-MM-DD"),"YYYYMMDD",datelocale)
}
const Td = ({children,style}) => (
    <td style={{verticalAlign:"middle",...style}}>{children}</td>
);

function getSelectedForwards(all,selected){
  return selected.map((id)=>{
    return all.filter(f=>f.id===id)[0];
  });
};

function countValidTargets(forwards) {
  return forwards.filter(f => f.valorable === true).length;
}

function filterTargetForwardsByDate(forwards, date){
  return forwards.map( forward => {
    let startdate = parseInt(internalDate(forward.start_date));
    let finishdate = parseInt(internalDate(forward.finish_date));
    let numberdate = parseInt(date);
    return {
      ...forward,
      valorable: startdate <= numberdate && numberdate <= finishdate,
    }
  }).filter(f => f.valorable);
};

function ForwardList({}){
    const history = useHistory();

    const [state,setState] = useState(VALID_FILTER);
    const [type,setType] = useState(ALL_TYPE);
    const [selected,setSelected] = useState([]);
    const [validValorationTargets,setValidValorationTargets] = useState([]);
    const [totalValue, setTotalValue] = useState(null);
    const [forwards,setForwards] = useState([]);
    const [isLoading,setLoading] = useState(true);
    const [valorating,setValorating] = useState({asking:false, loaded: false});
    const [availableDates,setAvailableDates] = useState([]);
    const [valorationDate,setValorationDate] = useState("");
    
    const enableValoration = selected.length > 0;
    const valorationText = enableValoration ? `Valorar: ${selected.length}` :'Valorar';

    function isSelected(foward){
        return selected.some(f=>f.id===foward.id);
    }
    function select(forward){
        setSelected([...selected, forward]);
    }
    function unselect(forward){
        setSelected(selected.filter(f=>f.id!==forward.id));
    }
    function changeType(type){
        setType(type);
        setLoading(true);
        setSelected([]);
    }
    function changeState(state){
        setState(state);
        setLoading(true);
        setSelected([]);
    }
    function preValoration(){
      setValorating({asking:true,loaded:false});
    }

    const selectAllFn = enableValoration ? () => setSelected([]) : () => setSelected(forwards);

    console.info('validValorationTargets',validValorationTargets, valorating.targets);

    async function callApi() {
        if (isLoading){
            let r = await api.getForwards({state,type});
            setForwards(r.data.data);
            setTotalValue(r.data.total);
            setLoading(false);
            return;
        } else if ( valorating.asking && !valorating.loaded ) {
          let forwardIds = selected.map(f => f.id);
          let r = await api.getAvailableValorationDates(forwardIds);
          let dates = r.data;
          setAvailableDates(dates);
          setValorationDate(dates[0]);
          setValorating({
            asking: true,
            loaded: true,
            nodate: dates.length===0,
          });
          setValidValorationTargets(filterTargetForwardsByDate(selected, valorationDate));
        } else if ( valorating.dovaloration) {
          console.info('call api', validValorationTargets);
          let r = await api.doValoration(valorationDate, validValorationTargets.map(f => f.id));
          let msg = "";
          let error = false;
          let pago = r.status === 402;
          if ( r.status === 500 ) {
            msg = "No se pudo valorar los forwards. Intente más tarde.";
            error = true;
          } else if (r.error) {
            msg = r.error;
            error = true;
            
          }else if ( r.data.length == 0 && false ) {
            msg = "Los forwards ya han sido valorados en la fecha seleccionada.";
          } else {
            msg = "Los forwards fueron valorados, haga click en el enlace para descargar los resultados."; 
          }

          setValorating({result:true, data: r.data, msg, error, pago});
        }
    }

    useEffect(()=>{callApi()},[isLoading,valorating, callApi, validValorationTargets]);

    if (
      isLoading ||
      (!valorating.asking && valorating.loaded) ||
      valorating.dovaloration
    ) {
      return (<Loading></Loading>);
    }
    

    return (
    <Container className="mb-5">
      <Row>
        <Col xs="5" style={{marginBottom:'1em'}}>
          <Button outline color="secondary" onClick={()=>history.push(`/forward/new`)}>
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
            &nbsp; Nuevo
          </Button>
        </Col>
        <Col xs="5">
            <ButtonGroup>
                <Button color="info" disabled={state===ALL_FILTER} onClick={()=>changeState(ALL_FILTER)}>Todos</Button>
                <Button color="info" disabled={state===VALID_FILTER} onClick={()=>changeState(VALID_FILTER)}>Vigentes</Button>
                <Button color="info" disabled={state===EXPIRED_FILTER} onClick={()=>changeState(EXPIRED_FILTER)}>Vencidos</Button>
                {/* <Button color="info" disabled={state==ARCHIVED_TYPE} onClick={()=>changeState(ARCHIVED_TYPE)}>Archivados</Button> */}
            </ButtonGroup>
            {/* &nbsp;&nbsp;&nbsp;
            <ButtonGroup>
                <Button color="info" disabled={type===ALL_TYPE} onClick={()=>changeType(ALL_TYPE)}>Todos</Button>
                <Button color="info" disabled={type===BUY_TYPE} onClick={()=>changeType(BUY_TYPE)}>Compra</Button>
                <Button color="info" disabled={type===SELL_TYPE} onClick={()=>changeType(SELL_TYPE)}>Venta</Button>
            </ButtonGroup> */}
            &nbsp;&nbsp;&nbsp;
            { forwards.length > 0 ? "Cantidad: "+ forwards.length : ""}
        </Col>
        <Col xs="2">
          <ButtonGroup>
              <Button color="primary" style={{width:"7em"}} disabled={!enableValoration} onClick={preValoration}>{valorationText}</Button>
              <Button color="primary" onClick={selectAllFn}><FontAwesomeIcon icon={ enableValoration ? "minus-square" : "check"}></FontAwesomeIcon></Button>
          </ButtonGroup>
        </Col>
      </Row>
      { forwards.length > 0?<></>:
        <Row>
            <Col style={centered}>
                <Alert color="warning">Sin coberturas, verifique los filtros o cree una nueva cobertura.</Alert>
            </Col>
        </Row>
      }
      <Row>
        <Col>
          <Table striped hover size="sm" style={{fontSize:"90%"}}>
            <thead>
              <tr>
                <th rowSpan="2" style={middleHeader}>Código</th>
                <th rowSpan="2" style={middleHeader}>Banco</th>
                <th rowSpan="2" style={middleHeader}>Valor (USD) <br />  <DisplayNumberFormat style={{fontFamily:"monospace",display:"inline-block", textAlign:"right", width:"100%"}} value={totalValue}></DisplayNumberFormat></th>
                <th rowSpan="2" style={middleHeader}>Tasa Strike</th> 
                <th colSpan="2" style={middleHeader}>Fechas (yyyy-mm-dd)</th>
                <th rowSpan="2" style={middleHeader}>Tipo</th>
                <th rowSpan="2" style={middleHeader}>Seleccionar</th>
              </tr>
              <tr>
                <th style={middleHeader}>Inicio</th>
                <th style={middleHeader}>Vencimiento</th>
              </tr>
            </thead>
            <tbody style={{fontFamily:'monospace'}}>
              {
                forwards.map((f)=>(
                    <tr key={f.id} style={middleHeader}>
                        <Td style={alignRight}>
                          <NavLink  to={`/forward/${f.id}/edit`}>{f.code}</NavLink>
                        </Td>
                        <Td style={alignLeft}>{truncate(f.bank,23)}</Td>
                        <Td style={alignRight}><DisplayNumberFormat value={f.value}></DisplayNumberFormat></Td>
                        <Td style={alignRight}><DisplayNumberFormat value={f.rate}></DisplayNumberFormat></Td>
                        <Td style={centered}>{humanDate(f.start_date)}</Td>
                        <Td style={centered}>{humanDate(f.finish_date)}</Td>
                        <Td style={centered}>{mapType(f.type)}</Td>
                        <Td >
                            { isSelected(f) ?
                                <Button color="link" size="sm" onClick={()=>unselect(f)}><FontAwesomeIcon icon="minus-square" /></Button>
                            :   <Button color="link" size="sm" onClick={()=>select(f)}><FontAwesomeIcon icon="check" /></Button>
                            }                            
                        </Td>
                    </tr>
                ))
              }
            </tbody>
          </Table>
          
        </Col>
      </Row>
      {
        valorating.asking && valorating.loaded && !valorating.nodate ?
        <Row>
          <Col>
          <Modal isOpen={true} className="valoration" size="lg">
            <ModalHeader>
              Prevaloración de ({selected.length}) coberturas
            </ModalHeader>
            <ModalBody style={{overflow: "auto",maxHeight: "20em",overflowX:"hidden"}}>
              <Row><Col>
                Solo se muestras las coberturas que pueden ser valoradas en la fecha seleccionada <Input
                  style={{maxWidth:"max-content",display:"inline"}} 
                  type="select"
                  value={valorationDate} 
                  onChange={e=>{
                    let newDate = e.target.value;
                    setValorationDate(newDate);
                    setValidValorationTargets(filterTargetForwardsByDate(selected || [], newDate));
                  }}
                >
                {
                  availableDates.map(d=>
                  <option key={d} value={d}>{humanInternalDate(d)}</option>
                    )
                }
                </Input>
                </Col></Row>
              {
                validValorationTargets.length > 0 ?
                <Row style={{marginTop:'1em'}}><Col>
                <Table striped hover size="sm" style={{fontSize:"90%"}}>
                  <thead>
                    <tr>
                      <th rowSpan="2" style={middleHeader}>Código</th>
                      <th rowSpan="2" style={middleHeader}>Banco</th>
                      <th rowSpan="2" style={middleHeader}>Valor (USD)</th>
                      <th rowSpan="2" style={middleHeader}>Tasa Strike</th> 
                      <th colSpan="2" style={middleHeader}>Fechas (yyyy-mm-dd)</th>
                      <th rowSpan="2" style={middleHeader}>Tipo</th>
                    </tr>
                    <tr>
                      <th style={middleHeader}>Inicio</th>
                      <th style={middleHeader}>Vencimiento</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    validValorationTargets.map( f=> 
                      <tr key={f.id} style={{
                        ...middleHeader,
                        textDecoration: !f.valorable ? 'line-through': 'none'
                        }}>
                        <Td style={alignRight}>{f.code}</Td>
                        <Td style={alignLeft}>{truncate(f.bank,23)}</Td>
                        <Td style={alignRight}><DisplayNumberFormat value={f.value}></DisplayNumberFormat></Td>
                        <Td style={alignRight}><DisplayNumberFormat value={f.rate}></DisplayNumberFormat></Td>
                        <Td style={centered}>{humanDate(f.start_date)}</Td>
                        <Td style={centered}>{humanDate(f.finish_date)}</Td>
                        <Td style={centered}>{mapType(f.type)}</Td>
                      </tr>
                    )
                  }
                  </tbody>
                </Table></Col></Row> : null
              }
            </ModalBody>
            <ModalFooter>
              {
                validValorationTargets.length > 0
                ? (<Button style={{minWidth:'120px'}} color="primary" onClick={()=>{setValorating({asking:false,loaded:false, dovaloration: true})}}>Valorar ({validValorationTargets.length})</Button>) 
                : null
              }
              <Button color="danger" onClick={()=>{setValorating({asking:false,loaded:false})}}>Cancelar</Button>{' '}
              
            </ModalFooter>
          </Modal>
          </Col>
        </Row>
      :<></>}
      {
        valorating.asking && valorating.loaded && valorating.nodate ?
        <Row>
          <Col>
          <Modal isOpen={true} className="valoration">
            <ModalBody style={{overflow: "auto",maxHeight: "20em",overflowX:"hidden"}}>
            No existen fechas de valoración para las coberturas seleccionadas.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={()=>{setValorating({asking:false,loaded:false})}}>Aceptar</Button>{' '}
            </ModalFooter>
          </Modal>
          </Col>
        </Row>
      :<></>}
      {
        valorating.result ?
        <Row>
          <Col>
          <Modal isOpen={true} className="valoration">
            <ModalBody style={{overflow: "auto",maxHeight: "20em",overflowX:"hidden"}}>
            {valorating.msg}
            <form method="POST" action={api.downloadFile}>
              <input type="hidden" name="token" value={api.currentToken()}></input>
              <input type="hidden" name="key" value={valorationDate}></input>
              <input type="hidden" name="ids" value={JSON.stringify(validValorationTargets.map(f => f.id))}></input>
              <br />
              { valorating.error ? <div></div>: <Button color="secondary">Descargar PDF</Button>
              }
            </form>
            <form method="POST" action={api.downloadXLSXFile}>
              <input type="hidden" name="token" value={api.currentToken()}></input>
              <input type="hidden" name="key" value={valorationDate}></input>
              <input type="hidden" name="ids" value={JSON.stringify(validValorationTargets.map(f => f.id))}></input>
              <br />
              { valorating.error ? <div></div>: <Button color="secondary">Descargar Excel</Button>
              }
            </form>
            </ModalBody>
            <ModalFooter>
              {
                valorating.pago
                ?
                <Button color="Seconday" onClick={()=>{history.push("/payment")}}>Pagar subscripcion</Button>
                :<span></span>
              }
              <Button color="primary" onClick={()=>{setValorating({asking:false,loaded:false})}}>Aceptar</Button>{' '}
            </ModalFooter>
          </Modal>
          </Col>
        </Row>
      :<></>
      }
    </Container>
    );
}

export default () => (
  <SecureComponent adminRequired={false}>
    <ForwardList></ForwardList>
  </SecureComponent>
);