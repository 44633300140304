import React from "react";
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from '../Apiprovider'

const url_wpp = process.env.REACT_APP_WPP

const Footer = () => {
  const authInfo = useApi();
  return (
    <footer className="bg-light p-3 text-center">
      {/* <div className="logo" /> */}
      < Container >
        <Row>
          <Col size={9} offset={2} style={{ textAlign: 'left' }}>
            <p style={{ textAlign: 'center' }}>{authInfo.isAuthenticated() ?
              <a href={url_wpp} style={{ color: 'green' }} target="_blank" rel="noreferrer">
                CONTÁCTENOS <FontAwesomeIcon icon={['fab', 'whatsapp']} />
              </a>
              : <></>}
            </p>
            <p style={{ fontWeight: 'bold' }}>Márgenes S.A.S.</p>
            <p>Calle 11 #100-121 | Edificio Campestre Towers |
              Oficina 701 |
              314 645 1231 |
              margenes @ margenes.biz |
              Cali - Colombia</p>
          </Col>
        </Row>
      </Container >
    </footer >
  );
}

export default Footer;
