import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Label } from "reactstrap";
import Loading from "../components/Loading";
import truncate from "truncate";
import datelocale from '../utils/datelocale';
import { format as dateFormat, parse as dateParse } from 'date-format-parse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SecureComponent from '../components/SecureComponent';
import api from '../api';
import objects from '../subscriptions';

const middleHeader = { textAlign: 'center', verticalAlign: 'middle' };
const centered = { textAlign: 'center' };
const alignLeft = { textAlign: 'left' };

function humanDate(datestr) {
    if (datestr == null) {
        return "-";
    }
    return dateFormat(dateParse(datestr, "YYYY-MM-DD"), "YYYY-MM-DD", datelocale)
}

const Td = ({ children, style }) => (
    <td style={{ verticalAlign: "middle", ...style }}>{children}</td>
);

function Suscription({ }) {

    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [alertMsg, setAlertMsg] = useState('');
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [editing, setEditing] = useState({ asking: false, edit: false });

    const enableEdit = selected.length >= 1;

    function isSelected(id) {
        return selected.some(i => i === id);
    }
    function select(id) {
        setSelected([...selected, id]);
    }
    function unselect(id) {
        setSelected(selected.filter(i => id != i));
    }

    function preEdit() {
        setEditing({ asking: true, loaded: true, edit: false });
        setSelectedRadio(null);
    }

    async function callApi() {
        try {
            if (isLoading) {
                let r = await api.getUsers();
                setUsers(r.data.data);
                setLoading(false);
                return;
            } else if (!isLoading && editing.asking && editing.edit) {
                await api.editSuscription(selected, selectedRadio);
                setEditing({ asking: false, loaded: false, edit: false });
                setLoading(true);
                setSelected([]);
                return;
            }
        } catch (error) {
            setAlertMsg(error);
            setError(true);
        }
    }

    useEffect(() => { callApi() }, [users, editing, isLoading]);
    console.info("state", users, editing, isLoading);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container className="mb-5">
            <Row>
                <Col xs="2"></Col>
                <Col xs="8"></Col>
                <Col xs="2" style={{ marginBottom: '1em' }}>
                    <Button color="primary" style={{ width: "7em" }} disabled={!enableEdit} onClick={preEdit}>Editar</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped hover size="sm" style={{ fontSize: "90%" }}>
                        <thead>
                            <tr>
                                <th rowSpan="2" style={middleHeader} >Correo</th>
                                <th rowSpan="2" style={middleHeader} >Nombre</th>
                                <th rowSpan="2" style={middleHeader} >Subscripci&oacute;n</th>
                                <th colSpan="2" style={middleHeader} >Fechas (yyyy-mm-dd)</th>
                                <th rowSpan="2" style={middleHeader} >Valoraciones</th>
                                <th rowSpan="2" style={middleHeader} >Editar</th>
                            </tr>
                            <tr>
                                <th style={middleHeader}>Inicio</th>
                                <th style={middleHeader}>Vencimiento</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontFamily: 'monospace' }}>
                            {
                                users.map((f) => (
                                    <tr key={f.id} style={middleHeader}>
                                        <Td style={alignLeft}>{truncate(f.email, 60)}</Td>
                                        <Td style={alignLeft}>{truncate(f.name, 60)}</Td>
                                        <Td style={centered}>{objects[f.plan_id]?.descripcion}</Td>
                                        <Td style={centered}>{humanDate(f.started_at)}</Td>
                                        <Td style={centered}>{humanDate(f.expire_at)}</Td>
                                        <Td style={centered}>{f.current_valorations}</Td>
                                        <Td>
                                            {isSelected(f.id) ?
                                                <Button color="link" size="sm" onClick={() => unselect(f.id)}><FontAwesomeIcon icon="minus-square" /></Button>
                                                : <Button color="link" size="sm" onClick={() => { select(f.id); }}><FontAwesomeIcon icon="check" /></Button>
                                            }
                                        </Td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {
                editing.asking && editing.loaded ?
                    <Modal isOpen={true} className="edit">
                        <ModalHeader>
                            Modificar subscripci&oacute;n:
                        </ModalHeader>
                        <ModalBody style={{ overflow: "auto", maxHeight: "40em", overflowX: "hidden" }}>
                            {
                                ["I","J","K","L"].map((key) => (
                                    <Row key={key} style={{ paddingBottom: "1rem" }}>
                                        <Col xs="1"></Col>
                                        <Col xs="0.5">
                                            < Input type="radio" name='selectedPlan' checked={key === selectedRadio} onChange={() => setSelectedRadio(key)} />
                                        </Col>
                                        <Label check>
                                            {[key, ": ", objects[key].descripcion]}
                                        </Label>
                                    </Row>
                                ))
                            }
                            <Row>
                                <Col xs="1"></Col>
                                <Col xs="0.5">
                                    <Input type="radio" checked={"x" === selectedRadio} onChange={() => setSelectedRadio("x")} />
                                </Col>
                                <Label check>
                                    Eliminar suscripcion!!! CUIDADO!
                                </Label>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => { setEditing({ asking: false }) }}>Cancelar</Button>{' '}
                            <Button color="primary" onClick={() => { setEditing({ asking: true, edit: true }); }}>Modificar</Button>{' '}
                        </ModalFooter>
                    </Modal>
                    : <></>
            }
            {error ?
                <Modal isOpen={true} className="edit">
                    <ModalHeader>
                        Error
                    </ModalHeader>
                    <ModalBody style={{ overflow: "auto", maxHeight: "40em", overflowX: "hidden" }}>
                        <Label> {alertMsg} </Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => { setError(false) }}>Cerrar</Button>{' '}
                    </ModalFooter>
                </Modal>
            : <></>
            }
        </Container>
    );
}


export default () => (
    <SecureComponent adminRequired={false}>
        <Suscription></Suscription>
    </SecureComponent>
);