import React, { useEffect, useRef, useState } from "react";
import {
  Container, Row, Col, Button, Alert, Input, Card, CardHeader, CardFooter, CardBody,
  CardTitle, CardText
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from "../components/Loading";
import SecureComponent from '../components/SecureComponent';
import api from '../api';
import plans from "../subscriptions"


const IDLE = 0;
const QUERY = 1;
const PENDING = 2;

const Offers = [
  {
    id: 1,
    title: 'Enterprise Basic',
    plan: [ "I","J"],
    texts: [
      "15 Valoraciones",
      "Excelente Soporte Técnico",
      "Manual de Uso en PDF"
    ],
    anualTexts:[
      "25% de Descuento por pago anual ($58.500/mes)"
    ],
    style: { width: "100%", backgroundColor: "#00adef", color: "white", padding: ".8em", textAlign:"center" }
  },
  {
    space: true
  },
  {
    id: 2,
    title: 'Enterprise Pro',
    plan: ["K","L"],
    texts: [
      "Valoraciones ILIMITADAS",
      "Soporte Técnico VIP",
      "1 Hora de Acompañamiento Inicial"
    ],
    anualTexts:[
      "50% de Descuento por pago anual ($73.900/mes)"
    ],
    style: { width: "100%", backgroundColor: "#333333", color: "white", padding: ".8em", textAlign:"center" }
  }
]


export const Payment = () => {

  const [currentState, setCurrentState] = useState("");
  const [state, setState] = useState(QUERY);
  const [showLoading, setShowLoading] = useState(true);
  const ref = useRef(state);
  ref.current = state;

  const callApi = async () => {
    if (ref.current === QUERY) {
      let r = await api.getProfile();
      if (r.status === 200) {
        let pending = r.data.subscription == null ? false : r.data.subscription.transaction_state == "P"
        if (pending) {
          setState(PENDING);
        } else {
          setState(IDLE);
        }
      }
    }
  };
  const timeout = async () => {
    if (ref.current === PENDING) {
      const timer = setTimeout(() => {
        console.log("timeout");
        setState(QUERY);
        setShowLoading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }

  };

  useEffect(() => { callApi() }, [state]);
  useEffect(() => { timeout() }, [state]);

  if (state === QUERY && showLoading) {
    return <Loading></Loading>;
  }
  if (state === PENDING || state !== IDLE && !showLoading) {
    return (
      <Alert color="info">
        Tienes un pago pendiente, continua con el pago o
        esperar hasta que termine la transacción.
      </Alert>);
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col>
          <h4 style={{ textAlign: "center" }}>Pago de suscripción</h4>
        </Col>
      </Row>
      
      <form autoComplete="off" action={api.startPaymentURL} method="POST" target="_blank" onSubmit={() => setTimeout(() => setState(QUERY), 0)} >
      <Row style={{marginTop:"1em"}}>
        {
          Offers.map((item) => {
            return (
              item.space ? <Col sm={2}></Col> :
              <Col sm={5}>
                <Card id={item.id}>
                  <CardHeader tag="h2" style={item.style}>{item.title}</CardHeader>
                  

                      <CardBody style={{paddingLeft:"3em"}}>
                        <CardTitle tag="h2" style={{ color: '#2ea3f2' }}>$ {plans[item.plan[0]].valor}</CardTitle>
                        
                        <CardText>
                        <Input type="radio"
                          value={item.plan[0]}
                          checked={currentState === item.plan[0]}
                          onChange={(e) => setCurrentState(e.target.value)}>
                        </Input>
                          <ul>
                            {
                            item.texts.map((text, idx)=>
                              <li key={idx}>{text}</li>
                            )}
                          </ul>
                          <hr />
                          <CardTitle tag="h2" style={{ color: '#2ea3f2' }}>$ {plans[item.plan[1]].valor}</CardTitle>
                        
                          <Input type="radio"
                          value={item.plan[1]}
                          checked={currentState === item.plan[1]}
                          onChange={(e) => setCurrentState(e.target.value)}>
                        </Input>
                          <ul>
                            {
                            item.anualTexts.map((text, idx)=>
                              <li key={idx}>{text}</li>
                            )}
                          </ul>  
                        </CardText>
                        
                      </CardBody>

                                      
                </Card>
              </Col>
            )
          })
        }
      </Row>
      <Row style={{ marginTop: "4em" }}>
          <Col sm={{ offset: 2, size: 8 }}>
            <p>
              Al presionar el boton de pago una nueva ventana sera abierta, termina el proceso en esa ventana y no cierres ni recargues esta ventana.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button color="primary" disabled={currentState === ""}><FontAwesomeIcon icon="" /> Pagar</Button>
              <input type="hidden" name="token" value={api.currentToken()} ></input>
              <input type="hidden" name="plan" value={currentState} ></input>
            </div>
          </Col>
        </Row>
      </form>
      
    </Container>
  );
};

export default () => (
  <SecureComponent adminRequired={false}>
    <Payment />
  </SecureComponent>
);
