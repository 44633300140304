import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Specs from '../specs';
import history from '../utils/history';
import { useApi } from "../Apiprovider";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const user = {};
  const auth = useApi();
  const isAuthenticated = auth.isAuthenticated();
  const isAdmin = auth.isAdmin();
  const logout = () => {
    auth.logout();
    history.replace('/');
  }

  const adminMenu = isAuthenticated ?
    Object.keys(Specs).map(menuItemId => {
      const itemSpec = Specs[menuItemId];
      return (
        <NavItem key={`menuItem-${menuItemId}`}>
          <NavLink tag={RouterNavLink} to={`/${menuItemId}`} activeClassName="router-link-exact-active" >{itemSpec.menuText}</NavLink>
        </NavItem>
      );
    }) : [];
  ;

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
              {adminMenu}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/forward"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Coberturas
                  </NavLink>
                </NavItem>
              )}
              
              {isAuthenticated && isAdmin && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/suscription"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Suscripciones
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button onClick={() => history.push('/login')} color={"primary"}>
                    Ingresar <FontAwesomeIcon icon="sign-in-alt" size={"1x"} />
                  </Button>
                </NavItem>
              )}
              {!isAuthenticated && (
                <NavItem>
                  <Button onClick={() => history.push('/signup')} color={"secondary"}>
                    Registrarse <FontAwesomeIcon icon="sign-in-alt" size={"1x"} />
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    {/* <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    /> */}
                    <FontAwesomeIcon icon="bars" size={"2x"} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Cuenta
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logout()}>
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Salir
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>

          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
