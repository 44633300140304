import React, { useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Profile from "./views/Profile";
import Payment from "./views/Payment";
import Generic from "./views/Generic";
import GenericEdit from "./views/GenericEdit";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import history from "./utils/history";
import Specs from './specs';
import { ApiProvider } from './Apiprovider'
import ForwardList from './views/ForwardList';
import ForwardEdit from './views/ForwardEdit';
import Suscription from './views/Suscription';

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
initFontAwesome();

const App = () => {

  const [token, setToken] = useState({});

  const specRoutes = Object.keys(Specs).flatMap(item => {
    const itemSpec = Specs[item];
    return [
      <Route exact path={`/${item}/new`} key={`route-${item}-new`}>
        <GenericEdit mode="new" key={`${item}-new`} spec={itemSpec}></GenericEdit>
      </Route>,
      <Route exact path={`/${item}/:id/edit`} key={`route-${item}-edit`}>
        <GenericEdit mode="edit" key={`${item}-edit`} spec={itemSpec}></GenericEdit>
      </Route>,
      <Route exact path={`/${item}`} key={`${item}-main`}>
        <Redirect to={`/${item}/year/latest`}></Redirect>
      </Route>,
      <Route path={`/${item}/year/:year`} key={`${item}-year`} render={routerProps =>
        <Generic key={routerProps.match.params.year} spec={itemSpec}></Generic>
      } />
    ];
  });

  return (
    <ApiProvider token={token} setToken={setToken}>
      <Router history={history}>
        <Switch>
          <div id="app" className="d-flex flex-column h-100">
            <NavBar />
            <Container className="flex-grow-1 mt-5">
              <Route path="/" exact component={Profile} />
              <Route path="/payment" exact component={Payment} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/profile" component={Profile} />
              <Route exact path="/forward" component={ForwardList} />
              <Route exact path="/suscription" component={Suscription} />
              <Route exact path="/forward/new" key="route-forward-new">
                <ForwardEdit mode="new" key="forward-new"></ForwardEdit>
              </Route>
              <Route exact path="/forward/:id/edit" key="route-forward-edit">
                <ForwardEdit mode="edit" key="forward-edit"></ForwardEdit>
              </Route>
              {specRoutes}
            </Container>
            <Footer />
          </div>
        </Switch>
      </Router>
    </ApiProvider>
  );
};

export default App;
