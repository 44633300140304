import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayNumberFormat } from "../components/NumberFormat";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { format as dateFormat, parse as dateParse } from 'date-format-parse';

import { useApi } from '../Apiprovider'
import Loading from "../components/Loading";
import SecureComponent from '../components/SecureComponent';
import api from '../api';

function formatId(id) {
  let d = dateParse(new String(id).toString(), 'YYYYMMDD');
  return dateFormat(d, "YYYY/MM/DD");
}

export const GenericComponent = ({ spec }) => {
  const history = useHistory();
  const urlyear = useParams().year || 'latest';

  const [state, setState] = useState({
    loading: true,
    data: {
      years: [],
      data: [],
      year: null
    },
    error: null,
  });

  const authInfo = useApi();

  const years = (state.data || {}).years || [];
  const items = (state.data || {}).data || [];
  const year = (state.data || {}).year || '';

  const changeYear = year => {
    history.push(`/${spec.entityId}/year/${year}`);
  };

  const callApi = async () => {
    try {
      if (!state.loading) return;
      const response = await api.getEntities(spec.entityId, urlyear);
      setState({
        ...state,
        loading: false,
        data: response.data,
        error: response.error
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
        loading: false
      });
    }
  };

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  useEffect(() => { callApi() }, [state]);

  if (state.loading) {
    return <Loading></Loading>
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col xs="2" style={{ marginBottom: '1em' }}>

          {
            authInfo.isAdmin() ?
              <Button outline color="secondary" onClick={() => history.push(`/${spec.entityId}/new`)}>
                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                &nbsp; Crear <span style={{ textTransform: 'uppercase' }}>{spec.entityId}</span>
              </Button> :
              <></>
          }

        </Col>
        <Col xs="8"></Col>
        <Col xs="2">
          <ButtonDropdown outline="true" color="secondary" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret color="secondary">
              {year}
            </DropdownToggle>
            <DropdownMenu>
              {
                years.filter(v => v !== state.year).map(y =>
                  <DropdownItem onClick={() => changeYear(y)} key={y}>{y}</DropdownItem>
                )
              }
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col xs={spec.tableSpec}>
          <Table striped hover size="sm">
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Fecha</th>
                {
                  spec.extraFields.map(f =>
                    <th key={f.name} style={{ textAlign: 'center' }}><span>{f.userText}</span></th>
                  )
                }
              </tr>
            </thead>
            <tbody style={{ fontFamily: 'monospace' }}>
              {
                items.map((item) =>
                  <tr key={item.id}>
                    <td style={{ textAlign: 'end' }}>
                      {
                        authInfo.isAdmin() ?
                          <NavLink to={`/${spec.entityId}/${item.id}/edit`}>{formatId(item.id)}</NavLink> :
                          formatId(item.id)
                      }
                    </td>
                    {
                      spec.extraFields.map(f =>
                        <td key={f.name} style={{ textAlign: 'end' }}>
                          <DisplayNumberFormat value={item[f.name]} decimalScale={spec.precision}></DisplayNumberFormat>
                        </td>
                      )
                    }
                  </tr>
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ({ spec }) => (
  <SecureComponent adminRequired={false}>
    <GenericComponent spec={spec}></GenericComponent>
  </SecureComponent>
);
