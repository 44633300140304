import React from "react";
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const baseProps = {
    thousandSeparator:'.',
    isNumericString: true,
    displayType:"text",
    decimalScale:2,
    decimalSeparator:',',
    fixedDecimalScale:true
}

const StyledInputNumberFormat = styled(NumberFormat)`
& {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    text-align: end;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
`;

export const DisplayNumberFormat = (props) => {
    const newProps = Object.assign({},baseProps,props,{
        displayType:"text"
    });
    return (
        <NumberFormat {...newProps}></NumberFormat>
    );
};

export const InputNumberFormat = (props) => {
    const newProps = Object.assign({},baseProps,props,{
        displayType:"input"
    });
    return (
        <StyledInputNumberFormat {...newProps}></StyledInputNumberFormat>
    );
};

