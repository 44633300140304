import React, { createContext, useContext, useState } from 'react';
import api from '../api';

const ApiContext = createContext({});
const useApi = () => useContext(ApiContext);

class Api {
    constructor({ token, admin }, setToken) {
        this._setToken = setToken || (console.warn("No setToken function defined"));
        this._token = token;
        this._admin = admin;

    }

    token() { return this._token; }

    isAdmin() { return this._admin === true; }

    isAuthenticated() { return this._token ? true : false; }

    async authenticate({ id, password }) {

        let r = await api.getToken({ email: id, password });
        if (r.status == 200) {
            return { ...r, id, password, type: 'password' };
        } else {
            return { error: r };
        }
    }

    async autologin() {
        try {
            const credential = await navigator.credentials.get({
                password: true,
                mediation: 'required',
            });
            if (credential == null) {
                return false;
            } else if (credential.type === 'password') {
                let r = await this.authenticate(credential)
                if (r.error) {
                    return false;
                }
                this._setToken(r.token);
                return r;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    async login(credential) {
        let r = await this.authenticate(credential)
        if (r.error) {
            return r.error;
        }
        try {
            const refreshcredential = await navigator.credentials.create({
                password: new window.PasswordCredential({ ...credential, mediation: 'required' }),
            })
            if (refreshcredential) {
                await navigator.credentials.store(refreshcredential)
            }
        } catch (e) {
            console.warn("Browser credentials not supported?", e);
        }
        this._setToken(r.token);
        return r;
    }

    logout() {
        this._setToken({});
        api.setToken(null);

        try {
            navigator.credentials.preventSilentAccess();
        } catch (e) {
            console.warn("Browser credentials not supported?", e);
        }
    }
}

const ApiProvider = ({ token, setToken, children }) => {
    return (
        <ApiContext.Provider value={new Api(token, setToken)}>{children}</ApiContext.Provider>
    );
}

export { ApiProvider, useApi };