import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container, Row, Col, Button, Form, FormGroup, Label, Alert,
  Modal, ModalBody, ModalFooter, Input
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from "../components/Loading";
import { useApi } from "../Apiprovider";
import history from "../utils/history";
import ModalHeader from "reactstrap/lib/ModalHeader";
import api from '../api';
import { faFontAwesomeLogoFull } from "@fortawesome/free-solid-svg-icons";
import log from '../logger';

const NONE = 0;
const AUTOLOGIN = 1;
const LOGIN = 2;
const DORECOVER = 4;

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState(AUTOLOGIN);
  const [error, setError] = useState("");
  const [askEmail, setAskEmail] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const authtenticator = useApi();
  const enableLogin = email.trim().length > 0 && password.length > 0;
  const enableRecover = email.trim().length > 0;

  const autologin = useCallback(async () => {
    if (state == AUTOLOGIN) {
      let r = await authtenticator.autologin();
      setState(NONE);
      if (r) {
        history.goBack();
      }
    }
    else if (state == LOGIN) {
      let r = await authtenticator.login({ id: email, password, type: 'password' });
      log(r);
      setState(NONE);
      if (r.status == 200) {
        history.goBack();
      } else {
        setError("Verifique usuario y contraseña");
      }
    }
    else if (askEmail && state == DORECOVER) {
      let r = await api.newPassword(email);
      setAlertMsg("Si estas registrado te enviamos una contraseña nueva. En caso de problemas por favor comunicate con nostros.");
      setState(NONE);
      setAskEmail(false);
    }
  }, [state]);

  useEffect(() => {
    autologin();
  }
    , [autologin]);

  if (state !== NONE) return <Loading></Loading>;

  const showError = error == "" ? <></> : <Alert color="danger">{error}</Alert>

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={{ size: 8, offset: 3 }}>
          <h3 style={{ marginBottom: "1em" }}>Iniciar Sesión</h3>
          {showError}
          <Form>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Email</Label>
              <Col sm={8}>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Contraseña</Label>
              <Col sm={8}>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3}></Label>
              <Col sm={8}>
                <Button disabled={!enableLogin} color="primary" onClick={(e) => { e.preventDefault(); setState(LOGIN) }}>Iniciar Sesión</Button>
                <Button disabled={!enableRecover} color="link" onClick={e => setAskEmail(true)}>Olvidé mi contraseña</Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Modal isOpen={askEmail}>
            <ModalHeader>
              Olvidé mi contraseña
            </ModalHeader>
            <ModalBody>
              <p>Si se encuentra registrado le enviaremos una contraseña nueva.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => { setState(DORECOVER); }}>Enviar contraseña</Button>{' '}
              <Button color="danger" onClick={(e) => setAskEmail(false)}>No</Button>{' '}
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
      {
        alertMsg == "" ? <></> :
          <Row>
            <Col>
              <Modal isOpen={true}>
                <ModalBody>
                  {alertMsg}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setAlertMsg('')}>Aceptar</Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
      }
    </Container>
  );
};

export default Login;
