import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button,Form, FormGroup, Label, Alert,
  Modal, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useHistory } from "react-router-dom";
import { InputNumberFormat } from '../components/NumberFormat';
import DatePicker from '../components/DatePicker';
import Loading from "../components/Loading";
import SecureComponent from "../components/SecureComponent";
import  api from '../api';
import Input from "reactstrap/lib/Input";
import { format as dateFormat, parse as dateParse } from 'date-format-parse';
const NONE = 10;
const SAVE = 11;
const DELETE = 12;
const DODELETE = 13;

const ForwardEditComponent = ({mode = "edit", spec}) => {
  const { id } = useParams();
  const history = useHistory();

  const isEdit = mode == "edit";
  const isNew = mode == "new";

  const [state,setState] = useState(NONE);
  const [isLoading,setLoading] = useState(isEdit);
  const [error,setError] = useState(false);

  const [data,setData] = useState({
    id: id, // Date
    code: '',
    bank: '',
    type: 'BUY',
    start_date: new Date(),
    finish_date: new Date(),
    value: 0,
    rate: 0    
  });

  const validForm = 
    data.start_date != null && 
    data.finish_date != null &&
    data.code != null && data.code.length > 0 &&
    data.bank != null && data.bank.length > 0 &&
    data.rate > 0 &&
    data.value > 0
  ;

  const dismissDialog = () => {
    setLoading(false);
    setState(NONE);
    setError(false);
  };
  const doDelete = () => {
    setLoading(true);
    setState(DODELETE);
    setError(false);
  };
  const doSave = () => {
    setLoading(true);
    setState(SAVE);
    setError(false);
  }
  const askDelete = () => {
    setState(DELETE);
    setError(false);
  };
  function setField(field,value) {
    setData({
      ...data,
      [field]: value
    });
    setError(false);
  }

  const callApi = async () => {
    try {
      if ( isEdit && state == DODELETE ) {
        let r = await api.deleteForward(id);
        if ( r.status == 200 ) {
          history.goBack();
          return;
        } else {
          setLoading(false);
          setError("No se puede borrar el registro.");
          setState(NONE);
        }
      }
      if ( isEdit && isLoading && state == NONE ) {
        let r = await api.getForward(id);
        if (r.status == 200) {
          setData({
            ...r.data,
            start_date : dateParse(r.data.start_date,'YYYY-MM-DD'),
            finish_date : dateParse(r.data.finish_date,'YYYY-MM-DD')
          }); // se debe convertir a string para hacer el parsing
        } else {
          setError("No se puede obtener el registro");
        }
        setLoading(false);
        return;
      }
      if ( state == SAVE ) {
        let f = isEdit ? api.updateForward : api.createForward;
        let postdata = {
          ...data,
          id:id,
          start_date: dateFormat(data.start_date,'YYYYMMDD'),
          finish_date: dateFormat(data.finish_date,'YYYYMMDD'),
        };
        let r = await f(postdata);
        if (r.status==409){
          setLoading(false);
          setError('Ya la cobertura');
          setState(NONE);
          return;
        }
        if (r.status==400){
          setLoading(false);
          setError(r.error);
          setState(NONE);
          return;
        }
        history.goBack();
      }
    } catch (e){
      setLoading(false);
      setError(new String(e));
      setState(NONE);
    }
  };

  useEffect(()=>{callApi()});

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col xs="12">
          <h4>
            { isNew?"Crear":"Modificar"} Cobertura
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={{size:6,offset:3}}>
          <Form>
            <FormGroup row>
              <Label style={{textAlign:'right'}} sm={3}>Banco</Label>
              <Col sm={6}>
                <Input type="text" value={data.bank} onChange={e=>setField('bank',e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{textAlign:'right'}} sm={3}>Código</Label>
              <Col sm={6}>
                <Input type="text" value={data.code} onChange={e=>setField('code',e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{textAlign:'right'}} sm={3}>Tipo</Label>
              <Col sm={6}>
                <Input sm={6} type="select" value={data.type} onChange={e=>setField('type',e.target.value)}>
                  <option value="BUY">Compra</option>
                  <option value="SELL">Venta</option>
                </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{textAlign:'right'}} sm={3}>Fecha Inicio</Label>
              <Col sm={6}>
                <DatePicker dateFormat="yyyy/MM/dd" placeholderText='YYYY/MM/DD' selected={data.start_date} onChange={change=>
                    setField('start_date',change)
                } strictParsing></DatePicker>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{textAlign:'right'}} sm={3}>Vencimiento</Label>
              <Col sm={6}>
                <DatePicker dateFormat="yyyy/MM/dd" placeholderText='YYYY/MM/DD' selected={data.finish_date} onChange={change=>
                    setField('finish_date',change)
                } strictParsing></DatePicker>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} style={{textAlign:'right'}}>Tasa Strike</Label>
              <Col sm={6}>
                <InputNumberFormat value={data.rate} onValueChange={change=> {
                    setField('rate',change.value);
                  }}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} style={{textAlign:'right'}}>Valor (USD)</Label>
              <Col sm={6}>
                <InputNumberFormat value={data.value} onValueChange={change=> {
                    setField('value',change.value);
                  }}/>
              </Col>
            </FormGroup>
            
            {
              error ? <FormGroup row>
              <Label sm={2}></Label>
              <Col sm={6}>
                <Alert color="warning">{error}</Alert>
              </Col>
            </FormGroup>:<></>
            }
            
            <FormGroup row>
              <Col sm={{offset:2,size:10}}>
                <Button color="primary" disabled={!validForm} onClick={doSave}><FontAwesomeIcon icon="save"/> Guardar</Button>
                &nbsp;
                {
                  isEdit ? 
                  <Button color="danger" onClick={askDelete}>
                    <FontAwesomeIcon icon="trash" /> Eliminar</Button>
                  : <></>
                }
              </Col>
              <Col sm={3}>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      {
        state!=DELETE?<></>:
        <Row>
          <Col>
          <Modal isOpen={true}>
            <ModalBody>
              ¿Estás seguro?
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={doDelete}>Si</Button>{' '}
              <Button color="primary" onClick={dismissDialog}>No</Button>{' '}
            </ModalFooter>
          </Modal>
          </Col>
        </Row>
      }
    </Container>
  );
};

export default ({mode}) => (
  <SecureComponent adminRequired={false}>
    <ForwardEditComponent mode={mode}></ForwardEditComponent>
  </SecureComponent>
);
