import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container, Row, Col, Button, Form, FormGroup, Label, Alert,
  Modal, ModalBody, ModalFooter, Input
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordStrengthBar from 'react-password-strength-bar';
import emailValidator from 'email-validator';
import Loading from "../components/Loading";
import { useApi } from "../Apiprovider";
import history from "../utils/history";
import ModalHeader from "reactstrap/lib/ModalHeader";
import api from '../api';
import { faFontAwesomeLogoFull } from "@fortawesome/free-solid-svg-icons";
import log from '../logger';

const NONE = 0;
const REGISTER = 1;

const SignUp = () => {

  const authtenticator = useApi();

  const [nombre, setNombre] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [nit, setNit] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [state, setState] = useState(NONE);
  const [error, setError] = useState("");

  const validEmail = emailValidator.validate(email);
  const okButton = nombre != "" && empresa != "" && nit != "" && validEmail && password != "" && password2 != "" && password == password2;
  const invalidPassword = password == password2 ? <></> : <Alert color="error">Las contraseña no es la misma</Alert>

  const process = useCallback(async () => {
    if (state == REGISTER) {
      let r = await api.register({
        nombre, empresa, nit, email, password
      });
      if (r.error) {
        setError(r.error);
        setState(NONE);
      } else {
        await authtenticator.login({ id: email, password, type: 'password' });
        history.replace("/");
      }
      
    }
  }, [state]);

  useEffect(() => {
    process();
  }
    , [process]);

  if (state !== NONE) return <Loading></Loading>;

  const showError = error == "" ? <></> : <Alert color="danger">{error}</Alert>

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={{ size: 8, offset: 3 }}>
          <h3 style={{ marginBottom: "1em" }}>Iniciar Sesión</h3>
          {showError}
          <Form>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Nombre</Label>
              <Col sm={8}>
                <Input type="input" value={nombre} onChange={(e) => setNombre(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Empresa</Label>
              <Col sm={8}>
                <Input type="input" value={empresa} onChange={(e) => setEmpresa(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>NIT</Label>
              <Col sm={8}>
                <Input type="input" value={nit} onChange={(e) => setNit(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row style={{ color: !validEmail && 'red' || "" }}>
              <Label style={{ textAlign: 'right' }} sm={3}>Email</Label>
              <Col sm={8}>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Contraseña</Label>
              <Col sm={6}>
                <Input type="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)}></Input>
                <br ></br>
                <PasswordStrengthBar password={password} scoreWords={['Débil', 'Débil', 'Normal', 'Bueno', 'Fuerte']} shortScoreWord={"Muy Corto"} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ textAlign: 'right' }} sm={3}>Repertir contraseña</Label>
              <Col sm={8}>
                <Input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)}></Input>
              </Col>
            </FormGroup>
            {password !== password2 && (
              <FormGroup row>
                <Label style={{ textAlign: 'right' }} sm={3}></Label>
                <Col sm={8}>
                  <Alert color="danger">Las contraseña no es la misma</Alert>
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Label sm={3}></Label>
              <Col sm={8}>
                <Alert color='info'>Recuerda! tienes un mes <span style={{ fontWeight: 'bold' }}>gratis</span> de cortesia ó <span style={{ fontWeight: 'bold' }}>15 valoraciones</span>, lo que suceda primero.</Alert>
              </Col>
              <Col sm={8}>
                <Button disabled={!okButton} color="primary" onClick={(e) => { e.preventDefault(); setState(REGISTER) }}>Registrarme</Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
