
// nro meses duracion, valor anual, numero valoraciones, iva?
const headers = ['meses', 'valor', 'valoraciones','descripcion'];
const subscription = {
    '0': [ 1,  0, 15, "1 mes gratis, hasta 15 valoraciones", 0], 
    A: [ 1,  80000, 15, "1 mes, hasta 15 valoraciones", 15200],
    B: [ 1, 150000,  0, "1 mes, más 15 valoraciones", 28500],
    C: [ 3, 150000, 15, "3 meses, hasta 15 valoraciones", 28500],
    D: [ 3, 250000,  0, "3 meses, más de 15 valoraciones", 47500],
    E: [ 6, 250000, 15, "6 meses, hasta 15 valoraciones", 47500],
    F: [ 6, 400000,  0, "6 meses, más de 15 valoraciones", 76000],
    G: [12, 400000, 15, "12 meses, hasta 15 valoraciones", 76000],
    H: [12, 650000,  0, "12 meses, más de 15 valoraciones", 123500],
    // LIGHT
    I: [ 1, "77,900",    15, "1 mes, hasta 15 valoraciones",0], // mensual
    J: [12, "702,000", 15*12, "12 mes, hasta 180 valoraciones",0], // anual
    K: [ 1, "147,900",     0, "1 mes, valoraciones ilimitadas",0], // mensual
    L: [12, "886,800",     0, "12 mes, valoraciones ilimitadas",0], // anual
};
let objects = {};
for(let planId in subscription ) {
    let o = {};
    headers.forEach((h,i)=>{
        o[h] = subscription[planId][i];
    });
    objects[planId] = o;
}

export default objects;
