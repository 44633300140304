import { library } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faLink, faPowerOff, faUser, faPlus, faSave, faTrash, faBars, faSignInAlt, faMinusSquare, faCheck } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faPlus);
  library.add(faSave);
  library.add(faTrash);
  library.add(faBars);
  library.add(faSignInAlt);
  library.add(faMinusSquare);
  library.add(faCheck);
  library.add(faWhatsapp);
}

export default initFontAwesome;
